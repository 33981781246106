<template>
    <div>
        <div class="wrap">
          <div class="title">
            Terms & Privacy Statement
          </div>
          <div class="flex-row">
            <div class="lnb">
              <div class="item"
                   v-for="item in list"
                   v-bind:key="item.term_no"
                   @click="changeItem(item)"
                   :class="{active:parseInt(selectedItemKey) === parseInt(item.term_no)}">
                {{ item.term_title }}
              </div>
            </div>
            <pre class="textarea" v-html="selectedItemContent"></pre>
          </div>
        </div>
    </div>
</template>
<script>
    export default {
        created() {
            let self = this
            this.$store.dispatch('terms/getTermList', {}).then((data) => {
                self.list = data
                if(self.$route.query.key === 'terms') {
                    self.changeItem(self.list[0])
                }
                else if(self.$route.query.key === 'privacy') {
                    self.changeItem(self.list[1])
                } else {
                    self.changeItem(self.list[0])
                }

            })
        },
        data () {
            return  {
              list: [

              ],
              selectedItemKey: null,
              selectedItemTitle:'',
              selectedItemContent:''
            }
        },
        mounted() {

        },
      methods: {
            changeItem(item) {
              this.selectedItemKey = this.$route.query.key = item.term_no;
              this.selectedItemTitle = item.term_title
              this.selectedItemContent = item.term_content
            }
        }
    }
</script>

<style scoped lang="scss">
@import "@/assets/scss/terms.scss";
</style>
